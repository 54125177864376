<template>

<!--用户管理 校园管理 班级管理 修改-->
  <div class="container">
    <div class="body-container">
      <div class="body-section">
        <div class="all-container">
          <div class="all-section">
            <div class="government-container">
              <div class="government-from_section">
                <el-form label-position="right" label-width="100px" :model="basicInfo" :rules="basicInfoRefs" ref="basicInfoRef">
                  <el-form-item label="班级" prop="isClass">
                    <el-input maxlength="11" v-model="basicInfo.isClass"></el-input>
                  </el-form-item>
                  <el-form-item label="班主任" prop="teacherId">
                    <el-select filterable v-model="basicInfo.teacherId" placeholder="请选择">
                      <el-option
                        v-for="item in teacherListData"
                        :key="item.teacherId"
                        :label="item.teacherName"
                        :value="item.teacherId">
                        <span style="float: left">{{ item.teacherName }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.phone }}</span>
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <!-- <el-form-item label="联系电话" prop="phone">
                    <el-input maxlength="11" v-model="basicInfo.phone"></el-input>
                  </el-form-item> -->
                </el-form>
                <div class="button-section">
                  <el-button class="add-button" @click="editClass">编辑</el-button>
                  <router-link :to="{ name: 'managementSchoolClass', query: { gradeId: this.basicInfo.newGradeId, schoolId: this.basicInfo.schoolId, gradeName: this.basicInfo.grade } }">
                    <el-button class="return-button">取消</el-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdministratorService from '@/globals/service/management/Administrator.js'

export default {
  data () {
    return {
      teacherListData: [],
      basicInfo: {
        schoolId: '',
        newGradeId: '',
        isClass: '',
        teacherId: '',
        phone: '',
        teacherData: null
      },
      basicInfoRefs: {
        isClass: [
          { required: true, message: '请输入班级', trigger: 'blur' }
        ],
        teacherId: [
          { required: true, message: '请选择老师', trigger: 'blur' }
        ]
        // phone: [
        //   { required: true, message: '请输入手机号码', trigger: 'blur' },
        //   { required: true, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
        //   {
        //     pattern: /^1[3456789]\d{9}$/,
        //     message: '目前只支持中国大陆的手机号码',
        //     trigger: 'blur'
        //   }
        // ]
      }

    }
  },
  created () {
    this.basicInfo.isClass = this.$route.params.item.className
    this.basicInfo.id = Number(this.$route.params.item.id)
    this.basicInfo.newGradeId = this.$route.query.gradeId
    this.basicInfo.schoolId = this.$route.query.schoolId
    this.basicInfo.grade = this.$route.query.gradeName
    this.getTeacher(this.basicInfo.teacherId)
  },
  methods: {
    getTeacher () {
      AdministratorService.AdministratorGetTeacherList({ schoolId: this.basicInfo.schoolId, pageSize: 50000, startPage: 1 }).then(res => {
        this.teacherListData = res.data.teacherList
        // this.basicInfo.oldTeacherId = Number(this.$route.params.item.teacherId)
        // console.log(this.$route.params.item)
        // this.basicInfo.teacherId = Number(this.$route.params.item.teacherId)
        AdministratorService.AdministratorSearchClassTeacher({ classId: this.basicInfo.id }).then(res => {
          if (this.teacherListData.some(item => item.teacherId === res.data.teacherId)) {
            this.basicInfo.teacherId = res.data.teacherId
          }
          this.basicInfo.oldTeacherId = res.data.teacherId
          this.basicInfo.teacherPhone = res.data.phone
          this.basicInfo.teacherName = res.data.teacherName
        })
      })
    },
    editClass () {
      if (!this.basicInfo.newGradeId || !this.basicInfo.schoolId) {
        this.$message({
          type: 'info',
          message: '没有关联学校班级，请返回'
        })
        return
      }
      // this.basicInfo.phone = this.teacherListData.filter(item => item.teacherId === this.basicInfo.teacherId)[0].phone
      this.$refs.basicInfoRef.validate(valid => {
        if (valid) {
          AdministratorService.AdministratoreditNewGradeClass(this.basicInfo).then(res => {
            this.$message({
              type: 'success',
              message: '编辑成功!'
            })
            this.$router.push({ name: 'managementSchoolClass', query: { gradeId: this.basicInfo.newGradeId, schoolId: this.basicInfo.schoolId, gradeName: this.basicInfo.grade } })
          })
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.container{
  width: 100%;
  min-height: 100%;
  flex: 1;
  background-color: #fff;
  color: #333;
  .body-container{
    padding: 80px 40px 20px 61px;
    .body-section{
      min-width: 450px;
    }
    .basic-container{
      max-width: 450px;
      .el-select{
        width: 100%;
      }
    }
    .all-container{
      .all-section-header{
          width: 100%;
          min-width: 160px;
          height: 50px;
          background-color: #F8F8F8;
          padding:  0 30px;
          border-radius: 6px;
          margin-bottom: 40px;
        .header-title{
          line-height: 50px;
          font-size: 18px;
          color: #333;
        }
      }
      .organization-from_section{
        max-width: 450px;
        /deep/.el-icon-camera{
          font-size: 50px;
          color: #34BBEC;
          margin-top: 50px;

        }
        /deep/.el-upload-dragger{
          width: 350px;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .government-from_section{
        max-width: 500px;
        width: 500px;
        .el-select{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .school-from_section{
        max-width: 450px;
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
      .class-from_section{
        max-width: 450px;
        .el-select, .el-autocomplete{
          width: 100%;
        }
        .button-section{
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          margin-top: 21px;
          padding-left: 20px;
          .add-button,.return-button{
            width: 120px;
            height: 40px;
            margin: 0 20px;
            border-radius: 20px;
            color: #fff;
            font-size: 16px;
          }
          .add-button{
            background-color: #34BBEC;
          }
          .return-button{
            background-color: #e4e4e4;
          }
        }
      }
    }
  }
}
/deep/.el-popper, .el-select-dropdown {
  .hover{
    background-color: #34BBEC !important;
    color: #fff !important;
  }
}
</style>
